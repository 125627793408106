// Define always use spanish language
moment.locale('es');
$.fn.modal.prototype.constructor.Constructor.DEFAULTS.backdrop = 'static';

// Global Vars
var SITE_URL = 'https://crm.prumisa.com/';
var baseurl = '';
var selectedVal;
var routeSocio = 'socio/ver/';
var routeCrearActividad = 'actividad/crear/';

// Global Msg
var restoreTitle = "Datos de Formulario";
var restoreMsg = "Se han almacenado datos en este formulario sin enviar";
var restoreButton = "&#191;Desea Restaurarlos?";
var restoreClass = "alert alert-warning alert-dismissible fade in";

//Font re-size
$(function () {
    var docCookies = {
        getItem: function (sKey) {
            if (!sKey) {
                return null;
            }
            return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
        },
        setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
            if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
                return false;
            }
            var sExpires = "";
            if (vEnd) {
                switch (vEnd.constructor) {
                    case Number:
                    sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
                    break;
                    case String:
                    sExpires = "; expires=" + vEnd;
                    break;
                    case Date:
                    sExpires = "; expires=" + vEnd.toUTCString();
                    break;
                }
            }
            document.cookie = encodeURIComponent(sKey) + "=" + encodeURIComponent(sValue) + sExpires + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "") + (bSecure ? "; secure" : "");
            return true;
        },
        removeItem: function (sKey, sPath, sDomain) {
            if (!this.hasItem(sKey)) {
                return false;
            }
            document.cookie = encodeURIComponent(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "");
            return true;
        },
        hasItem: function (sKey) {
            if (!sKey) {
                return false;
            }
            return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
        },
        keys: function () {
            var aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "").split(/\s*(?:\=[^;]*)?;\s*/);
            for (var nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) {
                aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]);
            }
            return aKeys;
        }
    };

    function change_size(element, size) {
        var new_size;
        var current = parseInt(docCookies.getItem("FontSize"));
        if (current !== "") {
            current = parseInt(element.css('font-size'));
        }
        if (size === 'default') {
            if (current != 14) {
                new_size = 14;
                //console.log('FontSizeD: '+new_size);
            }
        } else if (size === 'smaller') {
            if (current > 12) {
                new_size = current - 2;
                //console.log('FontSize-: '+new_size);
            }
        } else if (size === 'bigger') {
            if (current < 22) {
                new_size = current + 2;
                //console.log('FontSize+: '+new_size);
            }
        }

        element.css('font-size', new_size + 'px');
        docCookies.setItem("FontSize", new_size, Infinity);
    }

    $('#smallerSize').click(function () {
        change_size($('body'), 'smaller');
    })

    $('#defaultSize').click(function () {
        change_size($('body'), 'default');
    })

    $('#biggerSize').click(function () {
        change_size($('body'), 'bigger');
    });


    var fontSize = docCookies.getItem("FontSize");
    if (fontSize) {
        $('body').css('font-size', fontSize + 'px');
    }
});

// Search OCRD Button Sidebar Anywhere
function search_sidebar() {
    document.getElementById('form_searchsocio-nav').submit();
}
// Search OCRD Button Form Socio/Buscar/
function search_input() {
    document.getElementById('form_searchsocio').submit();
}

// Search OCRD
$(document).ready(function () {
    // Type Search Form
    $('#changeSearchType input:radio').change(function () {
        selectedVal = $("#changeSearchType input:radio:checked").val();
    });
    // Sidebar Form
    $('input.typeahead-nav').typeahead({
        valueKey: 'name',
        limit: 10,
        minLength: 2,
        highlight: true,
        remote: {
            url: SITE_URL + 'live/OCRD/%QUERY/1',
            replace: function () {
                var q = SITE_URL + "live/OCRD/" + encodeURIComponent($('input.typeahead-nav').val()) + "/1";
                console.log(baseurl + q);
                return baseurl + q;
            },
            filter: function (parsedResponse) {
                var dataset = [];
                for (i = 0; i < parsedResponse.length; i++) {
                    dataset.push({
                        name: parsedResponse[i].name,
                        id: parsedResponse[i].id
                    });
                }
                if (parsedResponse.length == 0) {
                    dataset.push({
                        name: "Sin resultados"
                    });
                }
                return dataset;
            },
        },
    })
    .bind('typeahead:selected', function (obj, datum) {
        if(datum.id)
            window.location.href = SITE_URL +  routeSocio + datum.id;
    });
    // /Buscar/Socio/ Form
    $('input.typeahead').typeahead({
        valueKey: 'name',
        limit: 10,
        minLength: 2,
        highlight: true,
        remote: {
            url: SITE_URL + 'live/OCRD/%QUERY/' + selectedVal,
            replace: function () {
                var q = SITE_URL + "live/OCRD/" + encodeURIComponent($('input.typeahead').val()) + "/" + encodeURIComponent(selectedVal);
                //console.log(baseurl + q);
                return baseurl + q;
            },
            filter: function (parsedResponse) {
                var dataset = [];
                for (i = 0; i < parsedResponse.length; i++) {
                    dataset.push({
                        name: parsedResponse[i].name,
                        id: parsedResponse[i].id
                    });
                }
                if (parsedResponse.length == 0) {
                    dataset.push({
                        name: "Sin resultados"
                    });
                }
                return dataset;
            },
        },
    })
    .bind('typeahead:selected', function (obj, datum) {
        if(datum.id)
            window.location.href = SITE_URL +  routeSocio + datum.id;
    });

    // /Actividad/Crear/ Form
    $('input.typeahead-create').typeahead({
        valueKey: 'name',
        limit: 10,
        minLength: 2,
        highlight: true,
        remote: {
            url: SITE_URL + 'live/OCRD/%QUERY/' + selectedVal,
            replace: function () {
                var q = SITE_URL + "live/OCRD/" + encodeURIComponent($('input.typeahead-create').val()) + "/" + encodeURIComponent(selectedVal);
                //console.log(baseurl + q);
                return baseurl + q;
            },
            filter: function (parsedResponse) {
                var dataset = [];
                for (i = 0; i < parsedResponse.length; i++) {
                    dataset.push({
                        name: parsedResponse[i].name,
                        id: parsedResponse[i].id
                    });
                }
                if (parsedResponse.length == 0) {
                    dataset.push({
                        name: "Sin resultados"
                    });
                }
                return dataset;
            },
        },
    })
    .bind('typeahead:selected', function (obj, datum) {
        if(datum.id)
            window.location.href = SITE_URL +  routeCrearActividad + datum.id;
    });
    //$('input.typeahead').on('typeahead:selected', function(event, selection) {});
});

//Tabs Load
$(function () {
    $('#tabs').on('click','.tablink,#myTab a',function (e) {
        e.preventDefault();
        var url = $(this).attr("data-url");

        if (typeof url !== "undefined") {
            var pane = $(this), href = this.hash;

        // ajax load from data-url
        $(href).load(url,function(result){
            pane.tab('show');
        });
    } else {
        $(this).tab('show');
    }
});

});

// Datatable 
$(function () {
    $('#dataTableInv').DataTable({
        "paging": false,
        "searching": false,
        "responsive": true,
        "scrollX": true,
        "ordering": true,
        "aaSorting": [[0, 'asc']],
        "language": {
            "url": SITE_URL+"js/datatable_es.json"
        }
    });
    $('#dataTableActivities').DataTable({
        "paging": true,
        "searching": true,
        "responsive": true,
        "scrollX": true,
        "ordering": true,
        "aaSorting": [[0, 'asc']],
        "pageLength": 25,
        "language": {
            "url": SITE_URL+"js/datatable_es.json"
        }
    });
    $('#dataTableInventory').DataTable({
        "paging": true,
        "searching": true,
        "responsive": true,
        "scrollX": true,
        "ordering": true,
        "aaSorting": [[1, 'desc']],
        "pageLength": 10,
        "language": {
            "url": SITE_URL+"js/datatable_es.json"
        }
    });
    $('#dataTableBatch').DataTable({
        "paging": true,
        "searching": true,
        "responsive": true,
        "scrollX": true,
        "ordering": true,
        "aaSorting": [[1, 'asc']],
        "pageLength": 10,
        "language": {
            "url": SITE_URL+"js/datatable_es.json"
        }
    });
});

//Print page
$(function() {

    $('.print').click(function() {
        var container = $(this).attr('rel');
        $('#' + container).printArea();
        return false;
    });

});

// Go top
$(document).ready(function(){
     $(window).scroll(function () {
            if ($(this).scrollTop() > 50) {
                $('#back-to-top').fadeIn();
            } else {
                $('#back-to-top').fadeOut();
            }
        });
        // scroll body to 0px on click
        $('#back-to-top').click(function () {
            $('#back-to-top').tooltip('hide');
            $('body,html').animate({
                scrollTop: 0
            }, 800);
            return false;
        });
        
        $('#back-to-top').tooltip('show');

});

// Zabuto Calendar
function ActivityCalendar($id)
{
    $("#events-calendar").zabuto_calendar({
        ajax: {
            url: SITE_URL + "live/OCLG/" + $id + "/zabutoCalendar",
            modal: true
        },
        legend: [
        {type: "block", label: " Actividad Registrada", classname: "badge badge-event"},
        {type: "block", label: " Hoy", classname: "badge badge-today"},
        ],
        language: "es",
        cell_border: true,
        today: true,
        show_days: true,
        weekstartson: 0,
        nav_icon: {
            prev: '<i class="fa fa-chevron-circle-left"></i>',
            next: '<i class="fa fa-chevron-circle-right"></i>'
        }
    });
}
// FullCalendar
$(document).ready(function () {
    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        $('#info').fullCalendar('render');
        $('#bootstrapModalFullCalendar').fullCalendar('render');
    });
    $('#myTab a:first').tab('show');
});

// Localstorage unsent form
$(document).ready(function () {
    $('.keepForm').validator().on('submit', function (e) {
        if (e.isDefaultPrevented()) {
            alert("Existe informaci\xf3n faltante en el formulario.");
        } else {
            if (navigator.onLine)
            {
                if (confirm("\277Esta segur@ que los datos son los correctos?"))
                    return true;
                else
                    return false;
            } else {
                alert("El navegador esta desconectado de la red; A\372n NO se han enviado los datos del formulario.");
            }
        }
    });
    $('.form-horizontal').validator().on('submit', function (e) {
        if (e.isDefaultPrevented()) {
            alert("Existe informaci\xf3n faltante en el formulario.");
        } else {
            if (navigator.onLine)
            {
                if (confirm("\277Esta segur@ que los datos son los correctos?"))
                    return true;
                else
                    return false;
            } else {
                alert("El navegador esta desconectado de la red; A\372n NO se han enviado los datos del formulario.");
            }
        }
    });
});

// LiveSearch OCPR
$(function () {
    $('#dataTableContacts').DataTable({
        "paging": false,
        "searching": false,
        "ordering": true,
        "info": false,
        "responsive": true
    });
    $("#CntctCode a").click(function () {
        selectedVal = $(this).data().id;
        cardcodeVal = $(this).data().code;

        $('#upd-form').trigger("reset");

        $.getJSON(SITE_URL+"live/OCPR/"+cardcodeVal+"/"+selectedVal, function (j) {
            for (var i = 0; i < j.length; i++) {
                CntctCode = j[i].id;
                CardCode = j[i].CardCode;
                Name = j[i].name;
                Location = j[i].Location;
                FirstName = j[i].FirstName;
                LastName = j[i].LastName;
                Tel1 = j[i].Tel1;
                Tel2 = j[i].Tel2;
                Mobile = j[i].Mobile;
                E_MailL = j[i].E_MailL;
                Position = j[i].Position;
            }
            $('#gCntctCode').val(CntctCode);
            $('#gCardCode').val(CardCode);
            $('#gName').val(Name);
            $('#gFirstName').val(FirstName);
            $('#gLastName').val(LastName);
            $('#gTel1').val(Tel1);
            $('#gTel2').val(Tel2);
            $('#gMobile').val(Mobile);
            $('#gE_MailL').val(E_MailL);
            $('#gPosition').val(Position);

            $("#updateOCPR").show();
            $("#createOCPR").hide();
        })
    });
    // Datatables Address
    $(document).ready(function() {
        var table = $('#dataTableAddress').DataTable({
            "paging": false,
            "searching": false,
            "ordering": true,
            "info": false,
            "responsive": true,
            "columnDefs": [
                { "visible": false, "targets": 2 }
            ],
            "order": [[ 2, 'asc' ]],
            "displayLength": 25,
            "drawCallback": function ( settings ) {
                var api = this.api();
                var rows = api.rows( {page:'current'} ).nodes();
                var last=null;

                api.column(2, {page:'current'} ).data().each( function ( group, i ) {
                    if ( last !== group ) {
                        $(rows).eq( i ).before(
                            '<tr class="group"><td colspan="5">'+group+'</td></tr>'
                        );

                        last = group;
                    }
                } );
            }
        } );

        // Order by the grouping
        $('#dataTableAddress tbody').on( 'click', 'tr.group', function () {
            var currentOrder = table.order()[0];
            if ( currentOrder[0] === 2 && currentOrder[1] === 'asc' ) {
                table.order( [ 2, 'desc' ] ).draw();
            }
            else {
                table.order( [ 2, 'asc' ] ).draw();
            }
        } );
    } );
    // Request Address to webservice
    $("#Address a").click(function () {
        selectedVal = $(this).data().id;
        sourceVal = $(this).data().source;
        typeVal = $(this).data().type;
        cardcodeVal = $(this).data().code;

        $('#upd-form').trigger("reset");

        $.getJSON(SITE_URL+"live/CRD1/"+selectedVal+"/"+cardcodeVal+"/"+typeVal, function (j) {
            for (var i = 0; i < j.length; i++) {
                Address = j[i].id;
                Street = j[i].Street;
                City = j[i].City;
                Country = j[i].Country;
                County = j[i].County;
                State = j[i].State;
                AdresType = j[i].AdresType;
                AdresTypeName = j[i].AdresTypeName;
                Block = j[i].Block;
                Location = j[i].Location;
            }
            $('#gAddress').val(Address);
            $('#gStreet').val(Street);
            $('#gCity').val(City);
            $('#gCountry').val(Country);
            $('#gCounty').val(County);
            $('#gState').val(State);
            $('#gAdresType').val(AdresType);
            $('#gAdresTypeName').val(AdresTypeName);
            $('#gBlock').val(Block);

            $("#updateCRD1").show();
            $("#createCRD1").hide();
        })
    })
});
// dataTable Dashboard Report
$(function () {
    var table = $('#dataTableDashboardReport').DataTable({
        "columnDefs": [
        { "visible": false, "targets": 2 }
        ],
        "order": [[ 2, 'asc' ]],
        "displayLength": 25,
        "drawCallback": function ( settings ) {
            var api = this.api();
            var rows = api.rows( {page:'current'} ).nodes();
            var last=null;

            api.column(2, {page:'current'} ).data().each( function ( group, i ) {
                if ( last !== group ) {
                    $(rows).eq( i ).before(
                        '<tr class="group"><td colspan="6">'+group+'</td></tr>'
                        );

                    last = group;
                }
            } );
        },
        "paging": true,
        "searching": true,
        "ordering": true,
        "info": true,
        "language": {
            "sProcessing": "Procesando...",
            "sLengthMenu": "Mostrar _MENU_ registros",
            "sZeroRecords": "No se encontraron resultados",
            "sEmptyTable": "Ning&uacute;n dato disponible en esta tabla",
            "sInfo": "Mostrando del _START_ al _END_ de _TOTAL_ registros",
            "sInfoEmpty": "No hay registros",
            "sInfoFiltered": "(filtrado de un total de _MAX_ registros)",
            "sInfoPostFix": "",
            "sSearch": "Buscar:",
            "sUrl": "",
            "sInfoThousands": ",",
            "sLoadingRecords": "Cargando...",
            "oPaginate": {
                "sFirst": "Primero",
                "sLast": "Último",
                "sNext": "Siguiente",
                "sPrevious": "Anterior"
            },
            "oAria": {
                "sSortAscending": ": Activar para ordenar la columna de manera ascendente",
                "sSortDescending": ": Activar para ordenar la columna de manera descendente"
            },
            "buttons": {
                "copy": "Copiar",
                "print": "Imprimir",
                "copyTitle": "Datos Copiados",
                "copyKeys": "Use your keyboard or menu to select the copy command",
                "copySuccess": {
                    1: "Se ha copiado una fila al portapapeles",
                    _: "Se han copiado %d filas al portapapeles",
                },
            }
        },
        "dom": "Bfrtip",
        "buttons": [
        "copyHtml5", "csvHtml5", "excelHtml5", "pdfHtml5", "print"
        ]
    } );

        // Order by the grouping
        $('#dataTableReport tbody').on( 'click', 'tr.group', function () {
            var currentOrder = table.order()[0];
            if ( currentOrder[0] === 2 && currentOrder[1] === 'asc' ) {
                table.order( [ 2, 'desc' ] ).draw();
            }
            else {
                table.order( [ 2, 'asc' ] ).draw();
            }
        } );
    });
// Search States
$(function () {
    $("select#country").change(function () {
        var key = $("select#country option:selected").attr('value');

        $.getJSON(SITE_URL+"live/OCST/"+key, function (j) {
            var options = '';
            options += '<option value="">Sin Seleccionar</option>';
            for (var i = 0; i < j.length; i++) {
                if (isInt(j[i].id))
                    options += '<option value="' + j[i].id + '">' + j[i].name + '</option>';
            }

            $("select#state").html(options);
        });
    });

});

// Mostar PrdtStatus de acuerdo al CntctType elegido
$(document).ready(function () {
    toggleFields();
    $("#Action").change(function () {
        toggleFields();
    });
    $("#CntctType").change(function () {
        toggleFields();
    });

});
function toggleFields() {
    if ($("#Action").val() == 'E' && $("#CntctType").val() == 2) {
        $("#Notas-Penetracion").show();
        $("#Notas-PushMoney").show();
    } else {
        $("#Notas-Penetracion").hide();
        $("#Notas-PushMoney").hide();
    }
    if ($("#Action").val() == 'E') {
        $("#Notas-Time").show();
    } else {
        $("#Notas-Time").hide();
    }
    if ($("#Action").val() != 'E') {
        $("#Time").show();
    } else {
        $("#Time").hide();
    }
    if ($("#Action").val() == 'M') {
        $("#Reunion-Direccion").show();
    } else {
        $("#Reunion-Direccion").hide();
    }
    if ($("#Action").val() == 'T') {
        $("#Tarea-Estado").show();
        $("#Time").addClass("col-md-2");
        $("#CntctCode").addClass("col-md-2");

    } else {
        $("#Tarea-Estado").hide();
        $("#Time").removeClass("col-md-2");
        $("#CntctCode").removeClass("col-md-2");
    }
}
// DateFunction
$(function () {
    var currentTime = new Date(),
    postTime = new Date(currentTime);
    postTime.setMinutes(currentTime.getMinutes() + 5);
        
    $('#Recontact').datetimepicker({
        format: ("YYYY-M-D HH:mm:ss"),
        stepping: 5,
        sideBySide: true,
        defaultDate: currentTime,
        ignoreReadonly: true
    }).on("dp.change", function (e) {
        var date = $(this).val();
        //console.log(date);
        $('#Recontact').attr('value', date);
    });
    
    $('#startDate').datetimepicker({
        format: ("YYYY-M-D HH:mm:ss"),
        stepping: 5,
        sideBySide: true,
        defaultDate: currentTime,
        ignoreReadonly: true
    });
    $('#endDate').datetimepicker({
        useCurrent: false,
        format: ("YYYY-M-D HH:mm:ss"),
        stepping: 5,
        sideBySide: true,
        defaultDate: postTime,
        ignoreReadonly: true
    });

    $("#startDate").on("dp.change", function (e) {
        $('#endDate').data("DateTimePicker").minDate(e.date);
    });
    $("#endDate").on("dp.change", function (e) {
        $('#startDate').data("DateTimePicker").maxDate(e.date);
    });
});
// Tabla dinámica
//var currentRow = 3;
$(document).ready(function () {
    $('#calx').calx();
    $('#calx').DataTable({
        "paging": false,
        "searching": false,
        "ordering": true,
        "filter": false,
        "language": {
            "sProcessing": "Procesando...",
            "sLengthMenu": "Mostrar _MENU_ registros",
            "sZeroRecords": "No se encontraron resultados",
            "sEmptyTable": "Ning&uacute;n dato disponible en esta tabla",
            "sInfo": "Mostrando del _START_ al _END_ de _TOTAL_ registros",
            "sInfoEmpty": "No hay registros",
            "sInfoFiltered": "(filtrado de un total de _MAX_ registros)",
            "sInfoPostFix": "",
            "sSearch": "Buscar:",
            "sUrl": "",
            "sInfoThousands": ",",
            "sLoadingRecords": "Cargando...",
            "oPaginate": {
                "sFirst": "Primero",
                "sLast": "Último",
                "sNext": "Siguiente",
                "sPrevious": "Anterior"
            },
            "oAria": {
                "sSortAscending": ": Activar para ordenar la columna de manera ascendente",
                "sSortDescending": ": Activar para ordenar la columna de manera descendente"
            }
        },
        "aoColumnDefs": [
        {"bSortable": false, "aTargets": [0, 1, 2, 3, 4]}
        ],
        "aaSorting": [[2, 'desc'], [0, 'asc']]
    });
});
$(document).ready(function () {
    $('#calx_gt').calx();
    $('#calx_gt').DataTable({
        "paging": false,
        "searching": false,
        "ordering": true,
        "filter": false,
        "language": {
            "sProcessing": "Procesando...",
            "sLengthMenu": "Mostrar _MENU_ registros",
            "sZeroRecords": "No se encontraron resultados",
            "sEmptyTable": "Ning&uacute;n dato disponible en esta tabla",
            "sInfo": "Mostrando del _START_ al _END_ de _TOTAL_ registros",
            "sInfoEmpty": "No hay registros",
            "sInfoFiltered": "(filtrado de un total de _MAX_ registros)",
            "sInfoPostFix": "",
            "sSearch": "Buscar:",
            "sUrl": "",
            "sInfoThousands": ",",
            "sLoadingRecords": "Cargando...",
            "oPaginate": {
                "sFirst": "Primero",
                "sLast": "Último",
                "sNext": "Siguiente",
                "sPrevious": "Anterior"
            },
            "oAria": {
                "sSortAscending": ": Activar para ordenar la columna de manera ascendente",
                "sSortDescending": ": Activar para ordenar la columna de manera descendente"
            }
        },
        "aoColumnDefs": [
        {"bSortable": false, "aTargets": [0, 1, 2, 3]}
        ],
        "aaSorting": [[0, 'asc']]
    });
});

// LiveSearch OCPR
$(function () {
    $("#CntctCodeRefresh").click(function () {
        $.getJSON("./typeahead/livesearchOCPR.php", {key: $(CardCode).val()}, function (j) {
            var options = '';
            options += '<option value="">Sin Seleccionar</option>';
            for (var i = 0; i < j.length; i++) {
                if(j[i].Location=='SAP')
                    options += '<option value="' + j[i].id + '">' + j[i].Location +' - '+ j[i].name + '</option>';
                if(j[i].Location=='WS')
                    options += '<option value="' + j[i].id + '">' + j[i].Location +' - '+ j[i].name + '</option>';
            }
            $("select#CntctCode").html(options);
            $("select#CntctPrsn").html(options);
        })
    })
});

// LiveSearch CRD1
$(function () {
    $("#AddressRefresh").click(function () {
        $.getJSON("./typeahead/livesearchCRD1.php", {key: $(CardCode).val()}, function (j) {
            var options = '';
            options += '<option value="">Sin Seleccionar</option>';
            for (var i = 0; i < j.length; i++) {
                options += '<option value="' + j[i].id + '">' + j[i].Location +' - '+ j[i].id + '</option>';
            }
            $("select#Address").html(options);
        })
    })
});

// Check if integer
function isInt(value) {
    return !isNaN(value) &&
    parseInt(Number(value)) == value &&
    !isNaN(parseInt(value, 10));
}

// LiveSelect OCLS
$(function () {
    $("select#CntctType").change(function () {
        var key = $("select#CntctType option:selected").attr('value');

        $.getJSON(SITE_URL+"live/OCLS/"+key, function (j) {
            var options = '';
            options += '<option value="">Sin Seleccionar</option>';
            for (var i = 0; i < j.length; i++) {
                if (isInt(j[i].id))
                    options += '<option value="' + j[i].id + '">' + j[i].name + '</option>';
            }

            $("select#CntctSbjct").html(options);
        });
    });

});

$(function () {
    $("select#uCountry").change(function () {
        var key = $("select#uCountry option:selected").attr('value');

        $.getJSON("./typeahead/livesearchOCST.php", {key: key}, function (j) {
            var options = '';
            options += '<option value="">Sin Seleccionar</option>';
            for (var i = 0; i < j.length; i++) {
                if (isInt(j[i].id))
                    options += '<option value="' + j[i].id + '">' + j[i].name + '</option>';
            }

            $("select#uState").html(options);
        });
    });

});

$(function () {
    $('#dataTable').DataTable({
        "paging": true,
        "searching": true,
        "ordering": true,
        "info": true,
        "responsive": true,
        "language": {
            "sProcessing": "Procesando...",
            "sLengthMenu": "Mostrar _MENU_ registros",
            "sZeroRecords": "No se encontraron resultados",
            "sEmptyTable": "Ning&uacute;n dato disponible en esta tabla",
            "sInfo": "Mostrando del _START_ al _END_ de _TOTAL_ registros",
            "sInfoEmpty": "No hay registros",
            "sInfoFiltered": "(filtrado de un total de _MAX_ registros)",
            "sInfoPostFix": "",
            "sSearch": "Buscar:",
            "sUrl": "",
            "sInfoThousands": ",",
            "sLoadingRecords": "Cargando...",
            "oPaginate": {
                "sFirst": "Primero",
                "sLast": "Último",
                "sNext": "Siguiente",
                "sPrevious": "Anterior"
            },
            "oAria": {
                "sSortAscending": ": Activar para ordenar la columna de manera ascendente",
                "sSortDescending": ": Activar para ordenar la columna de manera descendente"
            }
        }
    });
});

$(function() {

  $('input[name="dateRange1"]').daterangepicker({
      autoUpdateInput: false,
      "locale": {
        "format": "MM/DD/YYYY",
        "separator": " - ",
        "applyLabel": "Aplicar",
        "cancelLabel": "Cancelar",
        "fromLabel": "DESDE",
        "toLabel": "HASTATo",
        "customRangeLabel": "Personalizado",
        "weekLabel": "S",
        "daysOfWeek": [
        "Do",
        "Lu",
        "Ma",
        "Mi",
        "Ju",
        "Vi",
        "Sa"
        ],
        "monthNames": [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Setiembre",
        "Octubre",
        "Noviembre",
        "December"
        ],
        "firstDay": 1
    },
});

  $('input[name="dateRange1"]').on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
      $('input[name="sd"]').val(picker.startDate.format('MM-DD-YYYY'));
      $('input[name="ed"]').val(picker.endDate.format('MM-DD-YYYY'));
  });

  $('input[name="dateRange1"]').on('cancel.daterangepicker', function(ev, picker) {
      $(this).val('');
  });

  $(function() {
    if ("geolocation" in navigator){ //check geolocation available 
        //try to get user current location using getCurrentPosition() method
        navigator.geolocation.getCurrentPosition(function(position){ 
            
            $('input[name="latitude"]').val(position.coords.latitude);
            $('input[name="longitude"]').val(position.coords.longitude);
            
            console.log("Found your location \nLat : "+position.coords.latitude+" \nLang :"+ position.coords.longitude);
            });
    }else{
        console.log("Browser doesn't support geolocation!");
    }
});


});
